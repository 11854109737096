import React from "react";
import "./secondbanner.css";
import arrowIcon from "../../assets/BannerSection/arrow-icon.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import config from "../../config";
export default function SecondBanner() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`second-banner-section ${
        isVisible ? "section-animation" : ""
      }`}
      ref={ref}
    >
      <div className="container">
        <div className="second-banner-section-wrapper">
          <div className="second-banner-section-heading">
            <h2>Unlock the Power of Intelligent HR Automation</h2>
            <p>
              Elevate your HR processes with streamlined workflows, data-driven
              insights, and proactive talent management.
            </p>
            <div className="second-banner-button-wrapper">
              <a href={config.bookdemo.Link}>
                <button className="second-banner-button">
                  <p>Book Demo</p>
                  <img src={arrowIcon} alt="arrow-icon" />
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
