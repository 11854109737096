import React, { useEffect, useRef, useState } from "react";
import "./header.css";

import agentHRLogo from "../../assets/BannerSection/agent-hr-logo.png";

export default function Header() {
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isHeaderActive, setIsHeaderActive] = useState(false);
  const navLinks = useRef([]);
  const [ids] = useState({
    "About Us": "one",
    Solution: "two",
    Features: "three",
    "Use Cases": "four",
    FAQ: "five",
  });
  const scrollToSection = (id) => {
    const headerOffset = 100; // Adjust for fixed header height
    const sectionElement = document.getElementById(id);
    const elementPosition = sectionElement.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(event.target)
    ) {
      setIsMenuActive(false);
    }
  };

  useEffect(() => {
    if (isMenuActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuActive]);

  const handleScroll = () => {
    const diff = window.innerWidth < 1024 ? 20 : 90;
    setIsHeaderActive(window.scrollY > diff);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    handleScroll(); // To set initial state on mount
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        const mobileHamburger = document.querySelector(".mobile-hamburger");
        const mainMenu = document.querySelector(".main-navigation__menu");

        if (mobileHamburger) {
          mobileHamburger.classList.remove("is-activeHamburger");
        }
        if (mainMenu) {
          mainMenu.classList.remove("main-navigation__menu-active");
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const link = navLinks.current.find(
            (link) => link.dataset.target === entry.target.id
          );
          if (entry.isIntersecting) {
            link.classList.add("redirection-active");
          } else {
            link.classList.remove("redirection-active");
          }
        });
      },
      { threshold: [0.4] }
    );

    navLinks.current.forEach((link) => {
      if (!link) return;
      const sectionId = link.dataset.target;
      const sectionElement = document.getElementById(sectionId);
      observer.observe(sectionElement);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <header
      className={`pricing-header header ${isHeaderActive ? "active" : ""}`}
    >
      <div className="container">
        <nav>
          <div className="elixir-logo" onClick={scrollToTop}>
            <img src={agentHRLogo} alt="agent-hr-logo" />
          </div>
          <ul className="headder-content" ref={menuRef}>
            {["About Us", "Solution", "Features", "Use Cases", "FAQ"].map(
              (item, index) => (
                <li
                  key={index}
                  className="elixirdata-hover-element-header"
                  tabIndex="0"
                >
                  <a
                    href={`#${ids[item]}`}
                    data-target={ids[item]}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(ids[item]);
                    }}
                    ref={(el) => navLinks.current.push(el)}
                  >
                    <p className="platform">{item}</p>
                  </a>
                </li>
              )
            )}
          </ul>
        </nav>
        <div
          className={`mobile-navigation-header ${
            isHeaderActive ? "mobile-active" : ""
          }`}
        >
          <div className="mobile-logo-side">
            <a href="https://agenthr.ai">
              <div className="elixir-logo">
                <img src={agentHRLogo} alt="agent-hr-logo" />
              </div>
            </a>
          </div>
          <div
            className={`mobile-hamburger ${
              isMenuActive ? "is-activeHamburger" : ""
            }`}
            onClick={handleHamburgerClick}
            ref={hamburgerRef}
          >
            <div className="hamburger" id="hamburger-6">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>
        </div>
        <nav
          className={`main-navigation__menu ${
            isMenuActive ? "main-navigation__menu-active" : ""
          }`}
        >
          <div className="container">
            <ul id="redirection-links" ref={menuRef}>
              {["About Us", "Solution", "Features", "Use Cases", "FAQ"].map(
                (item, index) => (
                  <li key={index} tabIndex="0">
                    <a
                      href={`#${ids[item]}`}
                      data-target={ids[item]}
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSection(ids[item]);
                        setIsMenuActive(false);
                      }}
                      ref={(el) => navLinks.current.push(el)}
                    >
                      <p>{item}</p>
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}
