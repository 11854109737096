import React from "react";
import Banner from "../components/banner/banner";
import PartnerSection from "../components/PartnerSection/partner";
import UseCases from "../components/UseCasesSection/UseCases";
import FooterSection from "../components/FooterSection/footer";
import SecondBanner from "../components/SecondBannerSection/SecondBanner";
import SolutionSection from "../components/SolutionSection/SolutionSection";
import Features from "../components/Features/Features";
import ROI from "../components/ROISection/ROI";
import FAQ from "../components/FAQSection/FAQ";
import About from "../components/AboutSection/About";
import Header from "../components/Header/Header";

export default function AgentHR() {
  return (
    <>
      <Header />
      {/*To use header component, you need to comment out the useEffect at line number 96 or provide section ids as mentioned in line number 10*/}
      <Banner />
      <PartnerSection />
      <About />
      <SolutionSection />
      <Features />
      <UseCases />
      <ROI />
      <FAQ />
      <SecondBanner />
      <FooterSection />
    </>
  );
}
