import React, { useState } from "react";
import "./UseCases.css";
import cvAnalysis from "../../assets/UseCasesSection/cv-analysis.svg";
import cvAnalysisIcon from "../../assets/UseCasesSection/cv-analysis-icon.svg";
import automatedInterviews from "../../assets/UseCasesSection/automated-video-interviews.svg";
import automatedInterviewsIcon from "../../assets/UseCasesSection/automated-video-interviews-icon.svg";
import objectivePerformanceEvaluation from "../../assets/UseCasesSection/objective-performance-evaluation.svg";
import objectivePerformanceEvaluationIcon from "../../assets/UseCasesSection/objective-performance-evaluation-icon.svg";
import arrow from "../../assets/UseCasesSection/right-arrow.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const UseCases = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSwitch = (index) => {
    setActiveTab(index);
  };
  return (
    <section
      className={`use-cases-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="four"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>Use Cases</span>
            </div>
            <div className="heading-description">
              <h2>Unlocking Efficiency and Engagement in HR Operations </h2>
              <p>
                Explore how AgentHR revolutionizes HR processes with
                intelligent, context-aware solutions
              </p>
            </div>
          </div>
          <div className="second-tab-switch-wrapper">
            <div className="tab-links">
              <div
                className={`${activeTab === 1 ? "active" : ""} tab-1`}
                onClick={() => handleTabSwitch(1)}
              >
                <div className="tab-link-heading">
                  <img src={cvAnalysisIcon} alt="tab-icon" />
                  <h3>CV Analysis with Generative AI </h3>
                  <img src={arrow} alt="arrow" />
                </div>
                <p>
                  Efficiently screens resumes using generative AI, highlighting
                  the best-fit candidates for hiring teams.
                </p>
              </div>
              <div
                className={`${activeTab === 2 ? "active" : ""} tab-2`}
                onClick={() => handleTabSwitch(2)}
              >
                <div className="tab-link-heading">
                  <img src={automatedInterviewsIcon} alt="tab-icon" />
                  <h3>Automated Video Interviews</h3>
                  <img src={arrow} alt="arrow" />
                </div>
                <p>
                  Conducts automated video interviews, assessing candidates'
                  skills and cultural fit through AI analysis.
                </p>
              </div>
              <div
                className={`${activeTab === 3 ? "active" : ""} tab-3`}
                onClick={() => handleTabSwitch(3)}
              >
                <div className="tab-link-heading">
                  <img
                    src={objectivePerformanceEvaluationIcon}
                    alt="tab-icon"
                  />
                  <h3>Objective Performance Evaluation </h3>
                  <img src={arrow} alt="arrow" />
                </div>
                <p>
                  Standardizes performance evaluations with data-driven metrics,
                  ensuring fair and transparent employee assessments.
                </p>
              </div>
            </div>
            <div className="second-tab-switch-body">
              {activeTab === 1 && (
                <div
                  className={`second-tab-switch-content ${
                    activeTab === 1 ? "active" : ""
                  }`}
                >
                  <div className="tab-switch-body">
                    <img src={cvAnalysis} alt="tab-switch-image" />
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div
                  className={`second-tab-switch-content ${
                    activeTab === 2 ? "active" : ""
                  }`}
                >
                  <div className="tab-switch-body">
                    <img src={automatedInterviews} alt="tab-switch-image" />
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div
                  className={`second-tab-switch-content ${
                    activeTab === 3 ? "active" : ""
                  }`}
                >
                  <div className="tab-switch-body">
                    <img
                      src={objectivePerformanceEvaluation}
                      alt="tab-switch-image"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
