import React from "react";
import "./about.css";
import aboutUsImage from "../../assets/AboutSection/About.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
export default function About() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`about-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="one"
    >
      <div className="container">
        <div className="section-heading">
          <div className="heading-tag">
            <span>About</span>
          </div>
          <div className="heading-description">
            <h2>
              AgentHR transforms HR operations with intelligent automation.
            </h2>
            <p>
              Multi Agent system that can optimize HR workflows and enhancing
              employee engagement.
            </p>
          </div>
        </div>
        <div className="section-wrapper row">
          <div className="about-section-image">
            <img src={aboutUsImage} alt="about-us-image" />
          </div>
          <div className="about-section-content">
            <div className="pointers">
              <ul>
                <li>
                  <h3>Multi-Agent Coordination</h3>
                  <p>
                    Different agents manage recruitment, talent development,
                    performance reviews, and compliance, ensuring each HR
                    function is optimized.
                  </p>
                </li>
                <li>
                  <h3>Agentic Workflows</h3>
                  <p>
                    Intelligent agents work in sync to streamline processes,
                    providing personalized employee experiences.
                  </p>
                </li>
                <li>
                  <h3>AI Copilot</h3>
                  <p>
                    Agentic AI assistant understands employee queries across HR,
                    IT, finance, and more, delivering personalized answers
                    instantly.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
