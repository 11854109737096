import React from "react";
import "./banner.css";
import arrowIcon from "../../assets/BannerSection/arrow-icon.svg";
import bannerIcon1 from "../../assets/BannerSection/talent-management-gaps.svg";
import bannerIcon2 from "../../assets/BannerSection/lack-of-data-driven-insights.svg";
import bannerIcon3 from "../../assets/BannerSection/slow-hr-operations.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import config from "../../config";
const Banner = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [cardsRef, isCardsVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <section
      className={`banner-section ${isVisible ? "animate" : ""}`}
      ref={ref}
    >
      <div className="container">
        {/* Banner Content */}
        <div className="banner-content-wrapper">
          <h1>
            AgentHR: Revolutionizing
            <span> HR Operations</span> with Agentic AI
          </h1>
          <p>
            Streamlining HR tasks through intelligent multi-agent systems for
            enhanced efficiency and accuracy.
          </p>
          <div className="banner-button-wrapper">
            <a href={config.bookdemo.Link}>
              <button className="banner-button">
                <p>Book Demo</p>
                <img src={arrowIcon} alt="arrow-icon" />
              </button>
            </a>
          </div>
        </div>
        <div
          className={`banner-cards-outer ${
            isCardsVisible ? "reveal-animation" : ""
          }`}
          ref={cardsRef}
        >
          <div
            className={`banner-cards-inner ${
              isCardsVisible ? "slide-animation" : ""
            }`}
          >
            <div className="banner-cards-wrapper">
              <div className="banner-card banner-first-card">
                <div className="banner-card-icon">
                  <img src={bannerIcon1} alt="banner-card-icon" />
                </div>
                <div className="banner-card-text">
                  <h2>Enhancing Talent Management</h2>
                  <p>
                    Intelligent agents automate and optimize the hiring,
                    candidate screening, and performance evaluations, ensuring
                    that the right talent is hired.
                  </p>
                </div>
              </div>
              <div className="banner-card banner-second-card">
                <div className="banner-card-icon">
                  <img src={bannerIcon2} alt="banner-card-icon" />
                </div>
                <div className="banner-card-text">
                  <h2>Data-Driven Workforce Insights</h2>
                  <p>
                    Analyzes workforce data, helping HR teams make informed
                    decisions about employee engagement, retention.
                  </p>
                </div>
              </div>
              <div className="banner-card banner-third-card">
                <div className="banner-card-icon">
                  <img src={bannerIcon3} alt="banner-card-icon" />
                </div>
                <div className="banner-card-text">
                  <h2>
                    Streamlined HR <br /> Operations
                  </h2>
                  <p>
                    Automates repetitive HR tasks, freeing up time to manage
                    organizational culture, ensuring smooth and consistent
                    operational flow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
