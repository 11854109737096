import React from "react";
import "./Features.css";
import endToEndAutomation from "../../assets/FeatureSection/e2e-automation-icon.svg";
import decisionMaking from "../../assets/FeatureSection/decision-making-icon.svg";
import learningAndDev from "../../assets/FeatureSection/learning-dev-icon.svg";
import supportAgent from "../../assets/FeatureSection/support-icon.svg";
import performanceAnalytics from "../../assets/FeatureSection/performance-icon.svg";
import customizableDashboards from "../../assets/FeatureSection/dashboard-icon.svg";
import employeeSelfService from "../../assets/FeatureSection/employee-self-service-icon.svg";
import supportImage from "../../assets/FeatureSection/support-image.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";

export default function Features() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`features-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="three"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>Features</span>
            </div>
            <div className="heading-description">
              <h2>Revolutionary Features of AgentHR </h2>
              <p>
                Empowers HR teams with automated recruitment, personalized
                training, and insightful performance analytics.
              </p>
            </div>
          </div>
        </div>
        <div className="features-cards-wrapper row">
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 column-1">
            <div className="feature-cards">
              <div className="features-card first-card">
                <div className="feature-heading">
                  <img src={endToEndAutomation} alt="feature-icon" />
                  <h3>End to End automation </h3>
                </div>
                <div className="feature-description">
                  <p>
                    Streamline onboarding, time-off requests, and expense
                    approvals with intelligent automations that enhances
                    efficiency.
                  </p>
                </div>
              </div>
              <div className="features-card second-card">
                <div className="feature-heading">
                  <img src={decisionMaking} alt="feature-icon" />
                  <h3>Data-Driven Decision Making </h3>
                </div>
                <div className="feature-description">
                  <p>
                    By analyzing workforce data, AgentHR provides valuable
                    insights that help HR teams identify trends and make
                    informed strategic decisions.
                  </p>
                </div>
              </div>
              <div className="features-card third-card">
                <div className="feature-heading">
                  <img src={learningAndDev} alt="feature-icon" />
                  <h3>Learning and Development Support </h3>
                </div>
                <div className="feature-description">
                  <p>
                    By providing personalized training recommendations and
                    tracking employee development, AgentHR helps organizations
                    foster a culture of continuous learning.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 column-2">
            <div className="feature-cards">
              <div className="features-card fourth-card">
                <div className="feature-heading">
                  <img src={supportAgent} alt="feature-icon" />
                  <h3>24/7 Support Agent </h3>
                </div>

                <div className="feature-description">
                  <p>
                    Offer round-the-clock HR support to employees worldwide,
                    accommodating multiple languages and ensuring that help is
                    always available when needed.
                  </p>
                </div>
                <div className="support-image">
                  <img src={supportImage} alt="support-image" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 column-3">
            <div className="feature-cards">
              <div className="features-card fifth-card">
                <div className="feature-heading">
                  <img src={performanceAnalytics} alt="feature-icon" />
                  <h3>Performance Analytics </h3>
                </div>
                <div className="feature-description">
                  <p>
                    Utilize advanced analytics to assess employee performance,
                    providing managers with insights to guide performance
                    reviews
                  </p>
                </div>
              </div>
              <div className="features-card sixth-card">
                <div className="feature-heading">
                  <img src={customizableDashboards} alt="feature-icon" />
                  <h3>Customizable Dashboards </h3>
                </div>
                <div className="feature-description">
                  <p>
                    Offer HR professionals personalized dashboards that display
                    key metrics, trends, and alerts
                  </p>
                </div>
              </div>
              <div className="features-card seventh-card">
                <div className="feature-heading">
                  <img src={employeeSelfService} alt="feature-icon" />
                  <h3>Employee Self-Service Portal </h3>
                </div>
                <div className="feature-description">
                  <p>
                    Empower employees with a self-service portal where they can
                    manage personal information, submit requests, and access
                    resources.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
