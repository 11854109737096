import React, { useState } from "react";
import "./Solution.css";
import workplaceCulture from "../../assets/SolutionSection/workplace-culture.svg";
import performanceEval from "../../assets/SolutionSection/performance-evaluation.svg";
import talentManagement from "../../assets/SolutionSection/talent-management.svg";
import trainingDev from "../../assets/SolutionSection/training-development.svg";
import recruitmentSelection from "../../assets/SolutionSection/recruitment-selection.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
export default function SolutionSection() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [activeTab, setActiveTab] = useState(1);
  const handleTabSwitch = (index) => {
    setActiveTab(index);
  };
  return (
    <section
      className={`solution-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="two"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>Solutions</span>
            </div>
            <div className="heading-description">
              <h2>Comprehensive Solutions Tailored for Your HR Needs</h2>
              <p>
                AgentHR provides innovative solutions to enhance HR efficiency
                and engagement.
              </p>
            </div>
          </div>
          <div className="first-tab-switch-wrapper">
            <div className="tab-links-wrapper">
              <div
                className={`${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabSwitch(1)}
              >
                <p>Well-being</p>
              </div>
              <div
                className={`${activeTab === 2 ? "active" : ""}`}
                onClick={() => handleTabSwitch(2)}
              >
                <p>Hiring</p>
              </div>
              <div
                className={`${activeTab === 3 ? "active" : ""}`}
                onClick={() => handleTabSwitch(3)}
              >
                <p>Development</p>
              </div>
              <div
                className={`${activeTab === 4 ? "active" : ""}`}
                onClick={() => handleTabSwitch(4)}
              >
                <p>Management</p>
              </div>
              <div
                className={`${activeTab === 5 ? "active" : ""}`}
                onClick={() => handleTabSwitch(5)}
              >
                <p>Evaluation</p>
              </div>
            </div>
            <div className="first-tab-switch-body">
              {activeTab === 1 && (
                <div className={`${activeTab === 1 ? "active" : ""}`}>
                  <div className="first-tab-switch-content row">
                    <div className="tab-body-left">
                      <img src={workplaceCulture} alt="tab-switch-iamge" />
                    </div>
                    <div className="tab-body-right">
                      <div>
                        <h3>Workplace Culture & Well-being</h3>
                        <p>
                          AgentHR actively promotes a positive and inclusive
                          workplace culture.
                        </p>
                      </div>
                      <ul>
                        <li>
                          <h4>Proactive Employee Engagement</h4>
                        </li>
                        <li>
                          <h4>Diversity and Inclusion Tracking</h4>
                        </li>
                        <li>
                          <h4>Streamlined Communication Channels</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div className={`${activeTab === 2 ? "active" : ""}`}>
                  <div className="first-tab-switch-content row">
                    <div className="tab-body-left">
                      <img src={recruitmentSelection} alt="tab-switch-iamge" />
                    </div>
                    <div className="tab-body-right">
                      <div>
                        <h3>Recruitment & Selection</h3>
                        <p>
                          Foster continuous growth with tailored training and
                          development solutions.{" "}
                        </p>
                      </div>
                      <ul>
                        <li>
                          <h4>AI-Powered Screening</h4>
                        </li>
                        <li>
                          <h4>Candidate Matching</h4>
                        </li>
                        <li>
                          <h4>Automated Scheduling</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div className={`${activeTab === 3 ? "active" : ""}`}>
                  <div className="first-tab-switch-content row">
                    <div className="tab-body-left">
                      <img src={trainingDev} alt="tab-switch-iamge" />
                    </div>
                    <div className="tab-body-right">
                      <div>
                        <h3>Training & Development</h3>
                        <p>
                          Foster continuous growth with tailored training and
                          development solutions.
                        </p>
                      </div>
                      <ul>
                        <li>
                          <h4>Personalized Learning Paths</h4>
                        </li>
                        <li>
                          <h4>Real-Time Progress Tracking</h4>
                        </li>
                        <li>
                          <h4>Interactive Learning Modules</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 4 && (
                <div className={`${activeTab === 4 ? "active" : ""}`}>
                  <div className="first-tab-switch-content row">
                    <div className="tab-body-left">
                      <img src={talentManagement} alt="tab-switch-iamge" />
                    </div>
                    <div className="tab-body-right">
                      <div>
                        <h3>Talent Management </h3>
                        <p>
                          Optimize talent management with insights and strategic
                          planning tools.
                        </p>
                      </div>
                      <ul>
                        <li>
                          <h4>Data-Driven Insights</h4>
                        </li>
                        <li>
                          <h4>Succession Planning</h4>
                        </li>
                        <li>
                          <h4>Employee Engagement Tools</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 5 && (
                <div className={`${activeTab === 5 ? "active" : ""}`}>
                  <div className="first-tab-switch-content row">
                    <div className="tab-body-left">
                      <img src={performanceEval} alt="tab-switch-iamge" />
                    </div>
                    <div className="tab-body-right">
                      <div>
                        <h3>Performance Evaluation </h3>
                        <p>
                          Achieve fair evaluations with continuous feedback and
                          standardized criteria.
                        </p>
                      </div>
                      <ul>
                        <li>
                          <h4>Continuous Feedback Mechanism</h4>
                        </li>
                        <li>
                          <h4>Standardized Assessment Criteria</h4>
                        </li>
                        <li>
                          <h4>Goal Alignment</h4>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
