import React, { useState } from "react";
import "./FAQ.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqData = [
    {
      question: "What is AgentHR?",
      answer:
        "AgentHR is a multi agent platform designed to automate HR processes, enhance employee engagement, and streamline operations. It utilizes machine learning and natural language processing to assist HR professionals.",
    },
    {
      question: "How will AgentHR integrate with our existing systems?",
      answer:
        "AgentHR is designed for seamless integration with various HR systems, including Applicant Tracking Systems (ATS), Human Resource Management Systems (HRMS), and payroll software. It uses APIs to connect with existing platforms, ensuring data consistency and operational efficiency across systems.",
    },
    {
      question: "What are the security measures in place for AgentHR? ",
      answer:
        "AgentHR employs robust security protocols, including data encryption, multi-factor authentication, and regular security audits, to protect sensitive employee information. Compliance with industry standards such as GDPR and HIPAA is also maintained to ensure data privacy.",
    },
    {
      question: "How does AgentHR improve recruitment processes?",
      answer:
        "AgentHR automates various stages of the recruitment process, from job postings to candidate screening and scheduling interviews. This efficiency reduces the time-to-hire significantly, allowing HR teams to focus on strategic initiatives rather than administrative tasks.",
    },
    {
      question: "What types of organizations can benefit from AgentHR?",
      answer:
        "AgentHR is suitable for organizations of all sizes and industries looking to enhance their HR functions. Whether a small startup or a large corporation, AgentHR can scale its features to meet specific business needs.",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`first-accordion ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="five"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>FAQ</span>
            </div>
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="accordion">
            {faqData.map((item, index) => (
              <div className="accordion-item" key={index}>
                <button
                  id={`accordion-button-${index + 1}`}
                  aria-expanded={activeIndex === index ? "true" : "false"}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="accordion-title">{item.question}</span>
                  <span className="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
