import React from "react";
import "./roi.css";
import roiImage from "../../assets/ROI/roi-image.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import NumberDisplay from "../NumberDisplay";
export default function ROI() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`roi-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>ROI</span>
            </div>

            <div className="heading-description">
              <h2>Transformative ROI with AgentHR </h2>
              <p>
                Discover how AgentHR revolutionizes HR processes by delivering
                substantial returns on investment
              </p>
            </div>
          </div>
          <div className="roi-content-wrapper">
            <div className="roi-stats-cards row">
              <div className="roi-card">
                <NumberDisplay
                  first={20}
                  last={70}
                  char="%"
                  isVisible={isVisible}
                />
                <h4>Faster Issue Resolution </h4>
                <p>Enjoy quick and efficient HR issue resolution.</p>
              </div>
              <div className="roi-card">
                <NumberDisplay
                  first={30}
                  last={82}
                  char="%"
                  isVisible={isVisible}
                />
                <h4>Retention Boost </h4>
                <p>
                  Experience exceptional improvements in employee retention.
                </p>
              </div>

              <div className="roi-card">
                <NumberDisplay
                  first={19}
                  last={40}
                  char="%"
                  isVisible={isVisible}
                />
                <h4>Training Cost Reduction </h4>
                <p>Achieve significant savings on training expenses.</p>
              </div>
              <div className="roi-card">
                <NumberDisplay
                  first={15}
                  last={20}
                  char="%"
                  isVisible={isVisible}
                />
                <h4>Productivity gain </h4>
                <p>
                  Benefit from noticeable increases in employee productivity.
                </p>
              </div>
            </div>
            <div className="roi-image">
              <img src={roiImage} alt="roi-image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
